@charset "UTF-8";

#quickViewModal,
#maincontent{
  .dmj-item-list .product-tile .product-badge{
    left: 10px;
  }
  .product-badge{
    &:not([aria-hidden="true"]){
      display: flex;
      flex-wrap: wrap;
    }
    .product-icon-text{
      position: relative;
      padding: 2px 8px;
      min-height: 28px;
      font-size: 14px;
      display: grid;
      place-items: center;
      width: fit-content;
      line-height: 1;
      &::after{
        min-height: 28px;
        font-size: 14px;
        line-height: 1;
        letter-spacing: normal;
        white-space: nowrap;
        padding: 2px 6px;
        display: grid;
        place-items: center;
      }
      &-NEW{
        background-color: #FFE512;
        color: #000;
      }
      &-SALE{
        background-color: #c71835;
      }
      &-COLLABO{
        color: #fff;
        background-color: #5c0d0d;
      }
      &-WEB {
        background-color: #ff9d2b;
      }
      &-VEGAN {
        color: #fff;
        background-color: #146b00;
      }
      &-PRE {
        &.SALE{
          background-color: #FFE512;
          color: #000;
        }
      }
      &-GIFT {
        color: #fff;
        background-color: #000000;
      }

      &-LINE{
        color: #FFF;
        background-color: #06c755;
      }

      /* CSSでの書き換えが必要なクラス */

      &-OFF20,
      &-OFF30,
      &-OFF40,
      &-SP.SALE,
      &-NO.COUPON,
      &-LINE.APP,
      &-CP.MEMBER,
      &-LTD.TIME,
      &-LTD.JP,
      &-LTD.WEB{
        padding: 0;
        background: none;
        font-size: 0;
        position: relative;
      }

      &-OFF20,
      &-OFF30,
      &-OFF40{
        &::after{
          background: #c71835;
          color: #fff;
        }
      }
      &-OFF20{
        &::after{
          content: "20%OFF";
        }
      }
      &-OFF30{
        &::after{
          content: "30%OFF";
        }
      }
      &-OFF40{
        &::after{
          content: "40%OFF";
        }
      }
      &-SP{
        &.SALE {
          &::after{
            content: "特別SALE";
            color: #fff;
            background-color: #ff9d2b;
          }
        }
      }
      &-NO{
        &.COUPON {
          &::after{
            content: "クーポン対象外";
            color: #FFF;
            background-color: #999;
          }
        }
      }
      &-LINE {
        &.APP{
          &::after{
            content: "APP＆LINE";
            color: #000;
            background-color: #FFE512;
          }
        }
      }
      &-CP {
        &.MEMBER{
          &::after{
            content: "会員限定セール";
            color: #000;
            background-color: #FFE512;
          }
        }
      }
      &-LTD{
        &.TIME{
          &::after{
            content: "期間限定";
            color: #000;
            background-color: #FFE512;
          }
        }
        &.JP{
          &::after{
            content: "日本限定";
            color: #fff;
            background-color: #000;
          }
        }
        &.WEB{
          &::after{
            content: "WEB限定";
            background-color: #ff9d2b;
          }
        }
      }

    }
  }
}

.dmj-page-pdp .dmj-recommend{
  display: none;
}